import React from "react"

export default function Header() {
  return (
    <div style={{ margin: "auto", paddingTop: "24px", paddingBottom: "40px" }}>
      Under Construction. You can find me on Substack{" "} 
      <a href="https://bennettcapozzi.substack.com/"
      >
        here
</a>
    </div>
  )
}
